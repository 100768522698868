import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { PrimeraEtapa } from '../Interface/PrimeraEtapa';
import { vuelta } from '../Interface/Vuelta';
import { Pagar, datos } from '../Interface/pagar';
import { Finalizar } from '../Interface/finalizar';
import { TokenResponse } from '../Interface/token.interface';

@Injectable({
  providedIn: 'root'
})
export class Pasarela {

  constructor(private http:HttpClient) { }
  env = environment;
  
  primeraetapa(token:string,access_token:string):Observable<PrimeraEtapa[]>{
    const headers = {
      'Authorization': `Bearer ${access_token}`,
      'Ocp-Apim-Subscription-Key':this.env.primeraetapa.apikey
    };
    return this.http.get<PrimeraEtapa[]>(this.env.primeraetapa.url+token,{headers})
  }
  retornar(token:string):Observable<vuelta>{
    return this.http.post<vuelta>(this.env.retornar,{token:token})
  }
 
  pagarWebPay(token:string,access_token:string):Observable<datos>{
    const headers = {
      'Authorization': `Bearer ${access_token}`,
      'Ocp-Apim-Subscription-Key':this.env.pagarWebpay.apikey
    };
    return this.http.get<datos>(this.env.pagarWebpay.url+token,{headers})
  }
  pagarKhipu(token:string,access_token:string):Observable<datos>{
    const headers = {
      'Authorization': `Bearer ${access_token}`,
      'Ocp-Apim-Subscription-Key':this.env.pagarKhipu.apikey
    };
    return this.http.get<datos>(this.env.pagarKhipu.url+token,{headers})
  }
  pagarMercado(token:string,access_token:string):Observable<datos>{
    const headers = {
      'Authorization': `Bearer ${access_token}`,
      'Ocp-Apim-Subscription-Key':this.env.pagarMercado.apikey
    };
    return this.http.get<datos>(this.env.pagarMercado.url+token,{headers})
  }
  finalizar(token:string,access_token:string):Observable<Finalizar>{
    const headers = {
      'Authorization': `Bearer ${access_token}`,
      'Ocp-Apim-Subscription-Key':this.env.finalizarpago.apikey
    };
    return this.http.get<Finalizar>(this.env.finalizarpago.url+token,{headers})
  }
  login():Observable<TokenResponse>{
    const headers = {
      'Ocp-Apim-Subscription-Key':this.env.auth.apikey
    };
    return this.http.post<TokenResponse>(this.env.auth.url,{username:this.env.login.user,password:this.env.login.llave},{headers});
    
  }
}
