import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Pasarela } from '../../Service/pasarela.service'
import { PrimeraEtapa } from '../../Interface/PrimeraEtapa';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
@Component({
  selector: 'app-pasarela-pago',
  templateUrl: './pasarela-pago.component.html',
  styleUrls: ['./pasarela-pago.component.scss']
})

export class PasarelaPagoComponent implements OnInit {

  constructor(private route: ActivatedRoute, public pasarelaservice: Pasarela,private location: Location  ) { }
  seleccionQA:boolean = false
  isQA:boolean = false
  active:boolean = false
  active2:boolean = false
  active3:boolean = false
  inactive:boolean = false
  inactive2:boolean = false
  inactive3:boolean = false
  MWebpay: boolean = false;
  MKhipu: boolean = false;
  MMercado: boolean = false;
  OWebpay = [{ value: '1', label: 'WEBPAY (Tarjetas de Débito o Crédito)', checked: false }]
  OKhipu = [{ value: '1', label: '	KHIPU (Transferencia Electrónica)', checked: false }]
  OMercado = [{ value: '1', label: '	MERCADO PAGO', checked: false }]
  segundos: number = 120
  color = "#00ADEF"
  colorb = "primary"
  size = "60px"
  cargando = false
  punto = "."
  metodo = "";
  veralerta = false;
  type: "info" | "success" | "warning" | "error" = "error";
  message = "esto es una prueba";
  closeable = false

  ngOnInit(): void {
    this.isQA = environment.environment === 'QA'? true : false;
    let token = this.route.snapshot.paramMap.get('token') ?? '';
    var cuenta = setInterval(() => { this.contador(cuenta) }, 1000)
    setInterval(() => { this.puntos() }, 350)
    this.pasarelaservice.login().subscribe((data) => {
      this.pasarelaservice.primeraetapa(token, data.access_token).subscribe(
        (data) => {
          if (data) {
            data.forEach((medio: PrimeraEtapa) => {
              this.metodo += medio.disponible + ";"
            });
          } else {
            console.log("La respuesta no contiene la estructura esperada.");
          }
        },
        (error) => {
          console.error(error);
        }
      );
    })
  }
  cambiarambiete() {
    this.seleccionQA = !this.seleccionQA
  }
  contador(cuenta: NodeJS.Timer) {
    this.segundos--
    if (this.segundos == 0) {
      clearInterval(cuenta)
      this.location.back();
    }
  }
  qa(destino:string){
    let token = this.route.snapshot.paramMap.get('token')
    let url = 'https://mipagoqa'+destino+'.kaufmann.cl/pasarelapago/'+token
    window.location.href = url
  }
  puntos() {
    this.punto = this.punto.length > 3 ? "." : this.punto + ".";
    
  }
  pagar() {
    if (this.MWebpay || this.MKhipu || this.MMercado) {
      var medio = this.MWebpay ? 1 : (this.MKhipu ? 2 : (this.MMercado ? 3 : 0));
      this.cargando = true
      let token = this.route.snapshot.paramMap.get('token') ?? '';
      this.pasarelaservice.login().subscribe((data) => {
        if (this.MWebpay){
          this.pasarelaservice.pagarWebPay(token, data.access_token).subscribe((data) => {
            if (medio == 1) {
              const form = document.createElement('form');
              form.method = 'POST';
              form.action = data.url
              const tokenInput = document.createElement('input');
              tokenInput.type = 'hidden';
              tokenInput.name = 'token_ws';
              tokenInput.value = data.token
              form.appendChild(tokenInput);
              document.body.appendChild(form);
              form.submit();
            }
          })
        }
        if(this.MKhipu){
          this.pasarelaservice.pagarKhipu(token, data.access_token).subscribe((data) => {
              window.location.href = data.url;
          })
        }
        if(this.MMercado){
          this.pasarelaservice.pagarMercado(token, data.access_token).subscribe((data) => {
              window.location.href = data.url;
          })
        }
      })
    }
    else {
      this.message = "Debe seleccionar un metodo de pago"
      this.veralerta = true
    }

  }
  cambioW(event: any) {
    this.MWebpay = event.target.checked
    this.OWebpay[0].checked = true;
    
    this.active2 = false
    if (this.MWebpay) {
      this.veralerta = false
      this.MKhipu = false
      this.OKhipu[0].checked = false;
      this.MMercado = false
      this.OMercado[0].checked = false;
    }
    this.active = this.MWebpay
    if(this.active){
      this.inactive2 = true
      this.inactive3 = true
      this.inactive = false 
    }
    else{
      this.inactive2 = false 
      this.inactive3 = false
    }
  }
  cambioK(event: any) {
    this.MKhipu = event.target.checked
    this.OKhipu[0].checked = true;
    this.active = false
    if (this.MKhipu) {
      this.veralerta = false
      this.MWebpay = false
      this.OWebpay[0].checked = false;
      this.MMercado = false
      this.OMercado[0].checked = false;
    }
    this.active2 = this.MKhipu
    if(this.active2){
      this.inactive = true
      this.inactive3 = true
      this.inactive2 = false 
    }
    else{
      this.inactive = false 
      this.inactive3 = false 
    }
  }
  cambioM(event: any) {
    this.MMercado = event.target.checked
    this.OMercado[0].checked = true;
    if (this.MMercado) {
      this.veralerta = false
      this.MKhipu = false
      this.OKhipu[0].checked = false;
      this.MWebpay = false
      this.OWebpay[0].checked = false;
    }
    this.active3 = this.MMercado
    if(this.active3){
      this.inactive = true
      this.inactive2 = true
      this.inactive3 = false 
    }
    else{
      this.inactive = false 
      this.inactive2 = false 
    }
  }
}